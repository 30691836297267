import React, { Component } from 'react';
import toast from 'just-toasty';
import api from 'api';

class PeachPay extends Component {
	componentDidMount() {
		this.getCheckoutId();
	}

	loadScript = checkoutId => {
		const script = document.createElement('script');

		script.src = `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	};

	getCheckoutId = ids => {
		api.payment
			.getCheckoutId(ids)
			.then(res => {
				this.loadScript(res.data.checkoutId);
			})
			.catch(error => {
				toast('Failed to get checkoutId');
				console.error(error);
			});
	};

	render() {
		return (
			<div>
				<form
					action={`${window.location.hostname}/payment`}
					className="paymentWidgets"
					data-brands="VISA MASTER AMEX"
				>
					Test
				</form>
			</div>
		);
	}
}

export default PeachPay;
