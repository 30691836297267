import Container from 'components/common/container';
import { btn } from 'config/mixins';
import theme from 'config/theme';
import styled from 'styled-components';

const { colors, fontWeights, fontSizes, fonts, zIndices } = theme;

export const Content = styled(Container)`
	padding: 50px 14vw 80px 14vw;
	margin-bottom: 80px;
`;
export const PaymentContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
`;

export const PaymentInnerContainer = styled.div`
	min-height: 390px;
`;

export const Header = styled.header`
	position: fixed;
	display: flex;
	justify-content: space-between;
	width: 100%;
	z-index: ${zIndices.modal};
	left: 0;
	padding: 20px 14vw;
	height: 100px;
	align-items: center;
	top: 0;

	@media (max-width: 700px) {
		padding: 30px;
		background-color: ${colors.white};
	}
`;

export const CloseButton = styled.button`
	font-size: ${fontSizes.xxlarge};
	font-weight: ${fontWeights.bold};
	cursor: pointer;
	color: ${colors.darkBlue};
	background-color: transparent;
	outline: none;

	@media (max-width: 700px) {
		color: ${colors.blue};
	}
`;

export const Title = styled.h1`
	color: ${colors.darkBlue};
	font-weight: ${fontWeights.bold};
	font-family: ${fonts.Gilroy};
	font-size: ${fontSizes.xxxlarge};
	margin-bottom: 5px;
	text-align: center;
`;

export const Description = styled.p`
	color: ${colors.lightBlue};
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.small};
	font-family: ${fonts.Gilroy};
	margin-bottom: 20px;
	text-align: center;
`;

export const BorderCard = styled.div`
	border: ${colors.gray} solid 1px;
	border-radius: 1em;
	width: 600px;
	margin: 10px auto 0;
	padding: 15px 50px;
`;

export const PaymentMethods = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 10px 0;
`;

export const PaymentItem = styled.div`
	margin: 20px 0;
	display: flex;
	font-family: ${fonts.Gilroy};
	justify-content: space-between;
`;

export const PaymentOption = styled.div`
	font-weight: ${fontWeights.bold};
	padding: 0 5px;
	display: flex;
	align-items: center;
	&:hover {
		cursor: pointer;
	}
`;

export const Total = styled.div`
	display: flex;
	justify-content: space-between;
	width: 150px;
	margin: 10px 0 10px auto;
`;

export const Text = styled.p`
  font-size: ${fontSizes.xsmall};
  font-family: ${fonts.Gilroy};
  margin-top: 1.5em;
  ${({ centered }) => centered && 'text-align: center;'}
  ${({ onClick }) =>
		onClick
			? `
    cursor: pointer;
    color: ${colors.pink};
    text-decoration: underline;
    font-weight: ${fontWeights.normal};
  `
			: `
    color: ${colors.lightBlue};
    font-weight: ${fontWeights.thin};
  `}
  ${({ tip }) =>
		tip &&
		`
    padding-left: 1em;
    font-size: 0.7em;
    margin-top: 0.5em;
  `}
`;

export const Input = styled.input`
	width: 100%;
	font-size: ${fontSizes.medium};
	border-bottom: 2px solid ${colors.darkBlue};
	padding: 10px 20px;
	margin-bottom: 20px;
	color: ${colors.darkBlue};
	outline: none;
	background: ${colors.transparent};

	&::placeholder {
		color: ${colors.lightBlue};
	}

	&:focus {
		border-bottom-color: ${colors.pink};
	}
`;

export const Button = styled.button`
	${btn}
	outline: none;
	height: 4em;
	margin: 30px auto;
	width: 250px;

	&:disabled {
		opacity: 0.7;
		pointer-events: none;
	}
`;

export const LiveChat = styled.div`
	${btn}
	outline: none;
	margin: 30px auto;
	width: 250px;
	text-align: center;
	cursor: pointer;
	&:disabled {
		opacity: 0.7;
		pointer-events: none;
	}
`;
