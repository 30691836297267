import { btn } from 'config/mixins';
import theme from 'config/theme';
import styled from 'styled-components';

const { colors, fontWeights, fontSizes, fonts } = theme;

export const SnapscanCardContainer = styled.div`
	border-radius: 1em;
	background: ${colors.gray};
	width: 250px;
	margin: auto;
	overflow: hidden;
`;

export const SnapLink = styled.div`
	width: 250px;
	height: 250px;
	margin: auto;
`;

export const SnapImg = styled.img`
	width: 100%;
	padding: 40px;
`;

export const Text = styled.div`
	font-weight: ${fontWeights.normal};
	font-size: ${fontSizes.large};
	font-family: ${fonts.Gilroy};
	text-align: center;
	margin-bottom: 15px;
`;

export const SnapLogo = styled.div`
	width: 100%;
	background-color: #3278bd;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
`;

export const CheckingLoading = styled.div`
	width: 450px;
	margin: 70px auto 0;
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.xlarge};
	font-family: ${fonts.Gilroy};
	text-align: center;
`;

export const Button = styled.button`
	${btn}
	outline: none;
	height: 4em;
	margin: 30px auto;
	width: 250px;

	&:disabled {
		opacity: 0.7;
		pointer-events: none;
	}
`;

export const ButtonContainer = styled.div`
	margin: auto;
	width: 250px;
`;
