import api from 'api';
import Footer from 'components/common/footer';
import Header from 'components/common/header';
import Main from 'components/common/main';
import NProgressStyle from 'components/common/nprogress';
import withAuth from 'components/common/with-auth';
import theme from 'config/theme';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { ReactComponent as Check } from 'assets/images/listing/circle-check-icon.svg';
import { ReactComponent as UnCheck } from 'assets/images/listing/circle-uncheck-icon.svg';
import SnapscanQrCode from './snapscan';
import PeachPay from './peachpay';
import DigsLoader from 'components/common/digs-loader';
import {
	BorderCard,
	Description,
	PaymentItem,
	PaymentMethods,
	PaymentOption,
	Title,
	Button,
	Content,
	Total,
	PaymentContainer,
	PaymentInnerContainer,
} from './styles';
import toast from 'just-toasty';

const { colors } = theme;

const reducer = (accumulator, currentValue) => {
	return accumulator + currentValue;
};

class Payment extends Component {
	state = {
		failed: false,
		paymentMethod: 'snapscan',
		paymentStage: false,
		paymentItems: null,
		total: null,
		loading: false,
		noItems: false,
	};

	componentDidMount() {
		const query = new URLSearchParams(window.location.search);
		if (query.get('property')) {
			this.setState({ property: query.get('property') });
			this.getPaymentItems(query.get('property'));
		}
	}

	handleCheck = value => {
		this.setState({ paymentMethod: value });
	};

	handleProceed = () => {
		this.setState({ paymentStage: true });
	};

	getPaymentItems = property_uuid => {
		this.setState({ loading: true });
		api.payment
			.getPropertyPaymentItems(property_uuid)
			.then(res => {
				this.setState({
					paymentItems: res.data,
					total: res.data
						.map(item => {
							if (!item.paid) {
								return parseInt(item.outstanding_value);
							} else {
								return 0;
							}
						})
						.reduce(reducer, 0),
					loading: false,
					noItems: res.data.filter(item => !item.paid).length === 0,
				});
			})
			.catch(error => {
				toast('Failed to get payment items');
				console.error(error);
			});
	};

	getPaymentComponent = (value, props) => {
		switch (value) {
			case 'snapscan':
				return <SnapscanQrCode {...props} />;
			case 'credit_card':
				return <PeachPay {...props} />;
			default:
				return 'foo';
		}
	};

	paymentComplete = () => {
		const query = new URLSearchParams(window.location.search);
		if (query.get('next')) {
			this.props.history.push(query.get('next'));
		}
	};

	render() {
		const {
			paymentMethod,
			paymentStage,
			paymentItems,
			total,
			loading,
			noItems,
			property,
		} = this.state;
		return (
			<Main>
				<Helmet>
					<title>Payment</title>
					<meta name="description" content="Make a payment" />
				</Helmet>
				<NProgressStyle />
				<Header bgColor={colors.white} searchable />
				<Content bgColor={colors.lightGray}>
					<PaymentContainer>
						<PaymentInnerContainer>
							{loading ? (
								<DigsLoader />
							) : (
								<>
									{noItems ? (
										<>
											<Title>There are no items to pay</Title>
											<Description>
												If you think this is an error, please contact DigsConnect.
											</Description>
										</>
									) : (
										<>
											<Title>Payment</Title>
											<Description>Please make the following payment</Description>
											{paymentStage ? (
												this.getPaymentComponent(paymentMethod, {
													amount: total,
													items: paymentItems,
													property: property,
												})
											) : (
												<>
													<BorderCard>
														{loading ? (
															'Loading...'
														) : (
															<>
																{paymentItems &&
																	paymentItems.map(item => {
																		return (
																			<PaymentItem>
																				<div>{item.title}</div>
																				<div>
																					<strong>
																						{item.paid
																							? 'Paid'
																							: `R${item.outstanding_value / 100}`}
																					</strong>
																				</div>
																			</PaymentItem>
																		);
																	})}
															</>
														)}
													</BorderCard>
													<BorderCard>
														<Total>
															<div>Total:</div>
															<div>
																<strong>R{total / 100}</strong>
															</div>
														</Total>
													</BorderCard>
													<BorderCard>
														<PaymentMethods>
															<div>
																<strong>Choose payment method:</strong>
															</div>
															<div style={{ display: 'flex' }}>
																{/*<PaymentOption*/}
																{/*	onClick={() => this.handleCheck('credit_card')}>{paymentMethod === 'credit_card' ?*/}
																{/*	<Check style={{margin: '0 5px'}}/> :*/}
																{/*	<UnCheck style={{margin: '0 5px'}}/>} Credit*/}
																{/*	Card</PaymentOption>*/}
																<PaymentOption onClick={() => this.handleCheck('snapscan')}>
																	{paymentMethod === 'snapscan' ? (
																		<Check style={{ margin: '0 5px' }} />
																	) : (
																		<UnCheck style={{ margin: '0 5px' }} />
																	)}{' '}
																	Snapscan
																</PaymentOption>
															</div>
														</PaymentMethods>
													</BorderCard>
												</>
											)}
										</>
									)}
								</>
							)}
						</PaymentInnerContainer>
						{!noItems && !loading && !paymentStage && (
							<Button onClick={this.handleProceed} primary>
								Proceed
							</Button>
						)}
					</PaymentContainer>
				</Content>
				<Footer />
			</Main>
		);
	}
}

export default withAuth(Payment);
