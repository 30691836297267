import React, { Component } from 'react';
import {
	Button,
	ButtonContainer,
	CheckingLoading,
	SnapImg,
	SnapLink,
	SnapLogo,
	SnapscanCardContainer,
	Text,
} from './styles';
import SnapscanLogo from 'assets/images/snapscan/snapscan.png';
import api from 'api';
import DigsLoader from 'components/common/digs-loader';

class SnapscanQrCode extends Component {
	state = {
		loading: false,
		snapscan: null,
		checkPayment: false,
		paymentCheckCount: 0,
		payment_received: null,
	};

	componentDidMount() {
		this.getCheckout(this.props.property);
	}

	getCheckout = async property => {
		const response = await api.payment.getSnapscanCheckout(property);
		if (response.status === 200) {
			this.setState({ loading: false, snapscan: response.data });
		}
	};

	checkPayment = id => {
		this.setState({ checkPayment: true });
		this.interval = setInterval(() => this.getOrderStatus(id), 2 * 1000);
	};

	componentWillUnmount() {
		this.interval && clearInterval(this.interval);
	}

	getOrderStatus = async id => {
		if (this.state.paymentCheckCount < 20) {
			const response = await api.payment.getSnapscanOrderStatus(id);
			if (response.status === 200) {
				if (response.data.received) {
					this.setState(
						prevState => ({
							payment_received: true,
							checkPayment: false,
							paymentCheckCount: prevState.paymentCheckCount + 1,
						}),
						() => this.interval && clearInterval(this.interval),
					);
				} else {
					this.setState(prevState => ({ paymentCheckCount: prevState.paymentCheckCount + 1 }));
				}
			}
		} else {
			this.setState(
				{
					payment_received: false,
					checkPayment: false,
				},
				() => this.interval && clearInterval(this.interval),
			);
		}
	};

	render() {
		const { loading, snapscan, checkPayment, payment_received } = this.state;
		return checkPayment ? (
			<CheckingLoading>
				Checking payment status...
				<div style={{ marginTop: '45px' }}>
					<DigsLoader />
				</div>
			</CheckingLoading>
		) : payment_received ? (
			<CheckingLoading>Payment received</CheckingLoading>
		) : payment_received === null ? (
			<>
				<SnapscanCardContainer>
					{loading
						? 'Is Loading...'
						: snapscan && (
								<SnapLink>
									<a href={snapscan.url_link} target={'_blank'}>
										<SnapImg src={snapscan.qr_code} />
									</a>
								</SnapLink>
						  )}
					<Text>Snap here to pay</Text>
					<SnapLogo>
						<img src={SnapscanLogo} alt="Snapscan Logo" />
					</SnapLogo>
				</SnapscanCardContainer>
				<ButtonContainer>
					<Button primary onClick={() => this.checkPayment(snapscan.order_id)}>
						I've Paid
					</Button>
				</ButtonContainer>
			</>
		) : (
			<CheckingLoading>
				Payment hasn't reflected
				<p>Please contact support</p>
			</CheckingLoading>
		);
	}
}

export default SnapscanQrCode;
